<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="LBLPLANT" :value="chemData.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="자재명" :value="chemData.materialName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="자재코드" :value="chemData.materialCd"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="화학자재일련번호" :value="chemData.mdmChemMaterialId"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param.sync="popupParam"
            :chemData.sync="chemData"
            :contentHeight="contentHeight"
            @getDetail="getDetail"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chem-msds',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 공급업체 코드
        // mfgVendorCd: '',  // (사용안함) 제조업체 코드
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        mixFlag: '',  // 물질구성(단일 N/혼합 Y)
        casNo: '',  // CAS No.
        // hsNo: '',  // (사용안함) HS번호
        // specificGravity: '',  // (사용안함) 비중
        // serialNo: '',  // (사용안함) 고유번호(유독물번호or고시번호)
        // keNo: '',  // (사용안함) KE 번호
        // storagePlace: '',  // (사용안함) 보관장소
        productCategoryCd: null,  // 제품구분
        keepFacilityFlag: 'N',  // 보관시설 유무
        maxKeepAmount: '',  // 최대보관량
        storageFacilityFlag: 'N',  // 저장시설 유무
        maxStorageAmount: '',  // 최대저장량
        manufacturingFacilitiesFlag: 'N',  // 제조 사용시설 여부
        nanoManufacturingFlag: 'N',  // 나노물질 제조 여부
        nanoIncomeFlag: 'N',  // 나노물질 수입 여부
        nanoNoneApplicableFlag: 'N',  // 나노물질 해당없음 여부
        nanoUnknownFlag: 'N',  // 나노물질 모름 여부
        inputDissolutionTank: '',  // 투입 용해조
        productManufClassCd: null,  // 제품제조구분 코드
        chemIngredientsCd: '',  // 구성성분 작성근거
        useFlag: 'Y',  // 사용여부
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        psmFlag: 'N',  // PSM 대상 여부
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        mdmChemMaterialMsdsId: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        // specialCtype: '',  // (사용안함) 발암성 C-위험성평가용
        // specialRtype: '',  // (사용안함) 생식독성 R-위험성평가용
        // specialMtype: '',  // (사용안함) 생식세표변이원성 M-위험성평가용
        // rcode: '',  // (사용안함) 위험문구(R-phrase)-위험성평가용
        // hcode: '',  // (사용안함) 유해위험문구(H-code)-위험성평가용
        // cmrFlag: '',  // (사용안함) CMR물질여부-위험성평가용
        // boilingPoint: '',  // (사용안함) 끓는점-위험성평가용
        // hrCodeGrade: '',  // (사용안함) 위험문구/유해위험문구 등급-위험성평가용
        // maleficenceForm: '',  // (사용안함) 유해성 발생형태-위험성평가용
        // maleficenceValue: '',  // (사용안함) 유해성 측정값-위험성평가용
        // maleficenceGrade: '',  // (사용안함) 비산성 등급-위험성평가용
        // maleficenceType: '',  // (사용안함) 비산/휘발성 구분-위험성평가용
        // dayVolume: '',  // (사용안함) 하루취급량
        // dayVolumeUnit: '',  // (사용안함) 하루취급량 단위
        // ghsFlag: '',  // (사용안함) GHS 분류코드
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      plantCd: '',
      plantName: '',
      materialCd: '',
      materialName: '',
      editable: true,
      tabheight: '',
      cheminfo: {
        col1: '사업장1',
        col2: 'CM-00000001',
        col3: '가성소다',
      },
      tab: 'chemMsdsFile',
      tabItems: [
        { name: 'chemMsdsFile', icon: 'drive_folder_upload', label: 'MSDS', component: () => import(`${'./chemMsdsFile.vue'}`) },
        { name: 'chemAppraiseFile', icon: 'drive_folder_upload', label: '유/위해성<br>평가 결과', component: () => import(`${'./chemAppraiseFile.vue'}`) },
        { name: 'chemProcess', icon: 'gavel', label: '취급공정', component: () => import(`${'./chemProcess.vue'}`) },
        // { name: 'chemQuantity', icon: 'tag', label: '정량값', component: () => import(`${'./chemQuantity.vue'}`) },
        { name: 'chemLaw', icon: 'gavel', label: '규제사항', component: () => import(`${'./chemLaw.vue'}`) },
        { name: 'chemLicense', icon: 'assignment_turned_in', label: '관련 인허가', component: () => import(`${'./chemLicense.vue'}`) },
        // { name: 'chemDanger', icon: 'warning', label: '위험물 및<br/>지정수량', component: () => import(`${'./chemDanger.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.tabheight = (window.innerHeight - 200) + 'px'
      if (this.popupParam.mdmChemMaterialId) {
        if (this.popupParam.subtabName) {
          this.tab = this.popupParam.subtabName;
        }
      }
    },
    getDetail(selectedId, check) {
      this.$emit('getDetail', selectedId, check)
    },
  }
};
</script>